import React from "react";
import Home from "./Home";
class Wrapper extends React.Component {

    constructor(props) {     
        super(props);
        this.state = {

        }   
    }
     componentDidMount(){

      
      }
	render(){
		return (
            
            
            <Home/>

        
              
        );
	}
}

export default Wrapper;
