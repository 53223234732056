import React from "react";
class Home extends React.Component {

    constructor(props) {     
        super(props);
        this.state = {

        }   
    }
     componentDidMount(){

      
      }
	render(){
		return (
			<div className="nk-wrap">
            <header className="nk-header page-header is-transparent is-sticky is-shrink is-dark" id="header">
		    
            <div className="header-main">
                <div className="header-container container">
                    <div className="header-wrap">
                       
                        <div className="header-logo logo animated" data-animate="fadeInDown" data-delay=".65">
                            <a href="./" className="logo-link">
                                <img className="logo-dark" src="images/logo.png" srcSet="images/logo2x.png 2x" alt="logo"/>
                                <img className="logo-light" src="images/banner.png" srcSet="images/banner.png 2x" alt="logo"/>
                            </a>
                        </div>

                        <div className="header-nav-toggle">
                            <a href="#" className="navbar-toggle" data-menu-toggle="example-menu-04">
                                <div className="toggle-line">
                                    <span></span>
                                </div>
                            </a>
                        </div>
						
						
							

                       
                        <div className="header-navbar header-navbar-s1">
                            <nav className="header-menu" id="example-menu-04">
                                <ul className="menu menu-s2 animated" data-animate="fadeInDown" data-delay=".75">
                                    <li className="menu-item"><a  className="menu-link nav-link" href="/">Home</a></li>
                                    <li className="menu-item"><a className="menu-link nav-link" href="/">About</a></li>
                                    <li className="menu-item"><a className="menu-link nav-link" href="/">Innovation</a></li>
                                    <li className="menu-item"><a className="menu-link nav-link" href="/">Products</a></li>
                                    
                                </ul>
                                <ul className="menu-btns animated" data-animate="fadeInDown" data-delay=".85">
                                    <li><a href="#contact" className="btn btn-rg btn-auto btn-outline btn-grad on-bg-theme btn-round"><span>Contact</span></a></li>
                               </ul>
                            </nav>
                        </div>
                    </div>                                                
                </div>
            </div>
           
            <div className="header-banner bg-theme-dark" id={'home'}>
                <div className="nk-banner">
                    <div className="banner banner-mask-fix banner-fs banner-single tc-light">
                        <div className="banner-wrap">
                            <div className="container">
                                <div className="row align-items-center justify-content-center gutter-vr-30px">
                                    <div className="col-lg-6 order-lg-last">
                                        <div className="banner-gfx banner-gfx-re-s3 animated" data-animate="fadeInUp" data-delay="1.25">
                                            <img src="images/dark/gfx-d-dark.png" alt="header"/>
                                            <img className="banner-gfx-icon banner-gfx-icon-1" src="images/icons/icon-custom.png" alt=""/>
                                            <img className="banner-gfx-icon banner-gfx-icon-2" src="images/icons/icon-bitcoin.png" alt=""/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="banner-caption wide-auto text-center text-lg-left">
                                            <div className="cpn-head mt-0">
                                                <h1 className="title title-xl-2 title-semibold animated" data-animate="fadeInUp" data-delay="1.35"> Software Consulting, Development and Systems Integration</h1>
                                            </div>
                                            <div className="cpn-text cpn-text-s1">
                                                <p className="lead animated" data-animate="fadeInUp" data-delay="1.45">Innovation Enabled</p>
                                            </div>
                                            <div className="cpn-btns">
                                                <ul className="btn-grp animated" data-animate="fadeInUp" data-delay="1.55">
                                                   
                                                    <li><a href="https://wa.me/+254707222858" className="btn btn-md btn-grad btn-grad-alternet btn-round">Get in Touch</a></li>
                                                </ul>
                                            </div>
                                            <div className="cpn-social">
                                                <ul className="social">
                                                    <li className="animated" data-animate="fadeInUp" data-delay="1.65"><a href="https://www.facebook.com/technosilicon/"><em className="social-icon fab fa-facebook-f"></em></a></li>
                                                    <li className="animated" data-animate="fadeInUp" data-delay="1.75"><a href="https://www.linkedin.com/company/technosilicon"><em className="social-icon fab fa-linkedin"></em></a></li>
                                                 
                                                    <li className="animated" data-animate="fadeInUp" data-delay="1.7"><a href="https://twitter.com/technosilicon"><em className="social-icon fab fa-twitter"></em></a></li>
													{/* <li className="animated" data-animate="fadeInUp" data-delay="1.8"><a href="#"><em className="social-icon fab fa-github"></em></a></li>
                                                    <li className="animated" data-animate="fadeInUp" data-delay="1.85"><a href="#"><em className="social-icon fab fa-bitcoin"></em></a></li>
                                                    <li className="animated" data-animate="fadeInUp" data-delay="1.9"><a href="#"><em className="social-icon fab fa-medium-m"></em></a></li>
                                                
                                                */}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="nk-ovm mask-c-dark shape-v mask-contain-bottom"></div>
                
                
                <div id="particles-bg" className="particles-container particles-bg" data-pt-base="#00c0fa" data-pt-base-op=".3" data-pt-line="#2b56f5" data-pt-line-op=".5" data-pt-shape="#00c0fa" data-pt-shape-op=".2"></div>
            </div>
			
		</header>
			<main className="nk-pages">
            <section className="section mask-c-blend-dark bg-theme-dark-alt tc-light ov-h" id={'about'}>
                <div className="container">
                    
                    <div className="nk-block nk-block-text-wrap">
                        <div className="row align-items-center justify-content-center justify-content-md-between gutter-vr-30px">
                            <div className="col-mb-9 col-sm-7 col-md-6 col-lg-5 order-md-last">
                                <div className="nk-block-img animated" data-animate="fadeInUp" data-delay=".1">
                                    <img src="images/light/gfx-z-a.png" alt="app"/>
                                </div>
                            </div>
                            <div className="col-sm-10 col-md-6 text-center text-md-left">
                                <div className="nk-block-text">
                                    <h2 className="title animated" data-animate="fadeInUp" data-delay=".2">We build Plartforms, Providing Solutions to Businesses</h2>
                                    <p className="lead animated" data-animate="fadeInUp" data-delay=".3">Our Systems  are secure, smart and easy-to-use platforms, and completely disrupting the way businesses raise capital and operate.</p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
			<section className="section bg-theme-dark-alt tc-light py-0 ov-h">
                <div className="container" id="technologies">
                   
                    <div className="nk-block nk-block-features-2">
                        <div className="row align-items-center gutter-vr-30px justify-content-center justify-content-md-between">
                            <div className="col-mb-9 col-sm-7 col-md-6 col-lg-5">
                                <div className="gfx py-4 animated" data-animate="fadeInUp" data-delay=".1">
                                    <img src="images/dark/InnovationsSmall.png" alt="gfx"/>
                                </div>
                            </div>
                            <div className="col-sm-10 col-md-6 text-center text-md-left">
                                
                                <div className="nk-block-text">
                                    <h4 className="title title-lg animated" data-animate="fadeInUp" data-delay=".2">Innovations</h4>
                                    <p className="lead animated" data-animate="fadeInUp" data-delay=".3">TechnoSilicon provides state of the art platforms for the future, powering businesses while increasing efficiency</p>
                                    <p className="animated" data-animate="fadeInUp" data-delay=".4">While existing solutions offer to solve just one problem at a time, our team is up to build a secure, useful, &amp; easy-to-use products based on business needs. This includes integrating new systems with current systems, Developing Systems that are aligned with user requirements.</p>
                                    <p className="animated" data-animate="fadeInUp" data-delay=".5">At the end, Our aims to integrate all companies, employees, and business assets into a unified ecosystem, which will make business truly efficient, transparent, and reliable.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
			<section className="section section-contact bg-theme tc-light" id="contact">
                <div className="container">
                    <div className="section-head text-center wide-auto-sm">
                        <h2 className="title title-s4 animated" data-animate="fadeInUp" data-delay=".1" title="Contact">Contact TechnoSilicon</h2>
                        <p className="animated" data-animate="fadeInUp" data-delay=".2">Any question? Reach out to us and we’ll get back to you shortly.</p>
                    </div>
                    <div className="nk-block block-contact">
                        <div className="row justify-content-center text-center">
                            <div className="col-xl-8 col-lg-10">
                                <ul className="contact-list contact-list-s1 flex-wrap flex-md-nowrap pdb-l">
                                    <li className="animated" data-animate="fadeInUp" data-delay=".3">
                                        <em className="contact-icon fas fa-phone"></em>
                                        <div className="contact-text">
                                            <span>+254720735021</span>
                                        </div>
                                    </li>
                                    <li className="animated" data-animate="fadeInUp" data-delay=".4">
                                        <em className="contact-icon fas fa-envelope"></em>
                                        <div className="contact-text">
                                            <span>info@technosilicon.com</span>
                                        </div>
                                    </li>
									
                                    <li className="animated" data-animate="fadeInUp" data-delay=".5">
                                        <em className="contact-icon fab fa-facebook"></em>
                                        <div className="contact-text">
                                            <span>Follow Us Facebook</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </section>
			</main>
			<footer className="nk-footer bg-theme-alt section-connect">
            <div className="section section-m pb-0 tc-light ov-h">
                <div className="container py-4">
                   
                    <div className="nk-block pb-lg-5">
                        <div className="row justify-content-center text-center">
                            <div className="col-lg-6 col-md-9">
                                
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="nk-ovm shape-contain shape-center-top shape-p"></div>
            </div>
            <div className="section section-footer section-s tc-light bg-transparent">
                <div className="container">
                    
                    <div className="nk-block block-footer">
                        <div className="row">
                            <div className="col">
                                <div className="wgs wgs-text text-center mb-3">
                                    <ul className="social pdb-m justify-content-center">
									<li ><a href="https://www.facebook.com/technosilicon/"><em className="social-icon fab fa-facebook-f"></em></a></li>
                                    <li ><a href="https://www.linkedin.com/company/technosilicon"><em className="social-icon fab fa-linkedin"></em></a></li>
                                    <li><a href="https://twitter.com/technosilicon"><em className="social-icon fab fa-twitter"></em></a></li>
										 {
											 /*
										<li><a href="#"><em className="social-icon fab fa-facebook-f"></em></a></li>
                                        
                                        <li><a href="#"><em className="social-icon fab fa-linkedin"></em></a></li>
                                        <li><a href="#"><em className="social-icon fab fa-github"></em></a></li>
                                        <li><a href="#"><em className="social-icon fab fa-bitcoin"></em></a></li>
                                        <li><a href="#"><em className="social-icon fab fa-medium-m"></em></a></li>
											 */
										 }     
                                        
                                    </ul>
                                    <div className="copyright-text copyright-text-s3 pdt-m">
                                        <p><span className="d-sm-block">Copyright &copy; 2021, TechnoSilicon.  All rights Reserved.</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

			</div>
       
              
        );
	}
}


export default Home;