import React from "react";
 import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Wrapper from "./UI/Wrapper";
export default class App extends React.Component {

     componentDidMount(){

      
      }


	render(){
		return (
      <BrowserRouter>
      
        <Routes>                         
                <Route path='/' element={<Wrapper/>} /> 
                          
        </Routes>
      
    </BrowserRouter>
            
              
            

			
    );
	}
}
